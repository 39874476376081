<template>
  <v-app id="inspire">
    
      <v-navigation-drawer v-model="$store.state.drawer" :clipped="$vuetify.breakpoint.lgAndUp" app class="d-print-none print-push-left">
      <v-list dense >
        <template>
          <router-link to="/eps-admin-home" class="sidebar-link">
           <v-list-item link >
            <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
            </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
           </router-link>
         <v-divider></v-divider>
           <div v-for="link in links"
            :key="link.linkname">
          <router-link :to="{ name:link.link}" class="sidebar-link">
            
          <v-list-item link>
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.linkname }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </router-link>
         <v-divider></v-divider>
      </div>
            
        </template>
      </v-list>
    </v-navigation-drawer>

    <Header :drawer="$store.state.drawer"></Header>
  
    <v-content>
      <v-container fluid> 
             <router-view></router-view> 
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import axios from 'axios'
import Header from "../../components/shared/Header";
//import Header from "../components/shared/Header";
export default {
  //props: ['para_role_type'],
  props: {
    role:String,
  },
  data() {
      return {
          // rt:this.$route.query.roletype
          data:{},
          parentUrl:'',
          links:null,
          }
  },
  components: {
     Header:Header,
  },
  methods:{
    back(){
      this.$router.push('/eps-admin-home');
    }
  },
  watch:{
    
  },
  mounted(){
   
    axios.post("/SuperAdminHome/getlinks")
    .then(res =>{
      //window.console.log("res"+res.data)
      if(res.data.msg=="200"){
          // console.log(res.data)
          this.links = res.data.links
          this.parentUrl = this.$route.path
      }
      else{
      }
    })
    .catch(error => {
        window.console.log(error)
    })
  } 
}
</script>


<style scoped>
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(181, 186, 190) !important;
}
@media print {
  .print-push-left{
    margin-left: -256px;
    display: none !important;
  }
}
</style>